/**
 * Returns a random integer between min and max (both inclusive).
 * @param min - The minimum integer value.
 * @param max - The maximum integer value.
 * @returns A random integer between min and max.
 */
export function randomIntBetween(min: number, max: number): number {
  // Ensure min and max are integers
  min = Math.ceil(min);
  max = Math.floor(max);
  // Generate random integer between min and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns a random floating-point number between min (inclusive) and max (exclusive).
 * @param min - The minimum number value.
 * @param max - The maximum number value.
 * @returns A random number between min and max.
 */
export function randomFloatBetween(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}
